.newFriends
{
    width: 120%;
    margin: 0%;
    padding: 0%;
}

.friendCards
{
    margin: 0%;
    padding: 0%;
}