.button {
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border: 2px solid #fc6a01;
    border-radius: 10px;
    background-color: #fc6a01;
    color: white;
    text-align: center;
    transition: 0.2s;
    margin: 1%;
    margin-left: 23%;
    margin-top: 3%;
}

.button:hover {
    background-color: white;
    color: black;
    border-color: #fc6a01;
}

.item {
    border: 2px solid #fc6a01;
    border-radius: 16px;
    margin: 3%;
    padding: 5%;
    width: 130%;
    padding-bottom: 10%;
    padding-right: 10%;
    padding-left: 2%;
}

.image {
    width: 40%;
    border-radius: 100%;
    align-self: center;
    float: left;
    border: #fc6a01;
    border-width: 2px;
}

.image:hover {
    width: 42%;
}

.friendRightContainer
{
    float: right;
}

.largebody
{
    padding: 0px;
}
