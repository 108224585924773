.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fc6a01;
    color: white;
    text-align: center;
    margin: 0 1rem;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 5%;
    width: 50%;
  }

.button:hover {
    background-color: white;
    color: black;
    border-color: #fc6a01;
}

input
{
    border-radius: 3px;
    padding: 5px;
    width: 100%;;
}