.button {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border: 2px solid #fc6a01;
  border-radius: 10px;
  background-color: #fc6a01;
  color: white;
  text-align: center;
  font: 700 1rem "Istok Web";
  transition: 0.2s;
}

.button:hover {
  background-color: white;
  color: black;
  border-color: #fc6a01;
}

.eventCard {
  max-width: 40rem;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.75rem #666;
  overflow: hidden;
}

.eventCard + .eventCard {
  margin-top: 1rem;
}

.eventCard > h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #fc6a01;
  color: white;
  padding: 1rem 1.25rem 0.5rem 1.25rem;
}

.eventCard > h3 > .eventTitle {
  font-size: 1.5rem;
}

.eventCard > h3 > .eventHost {
  font-size: 1rem;
}

.eventCard > div {
  padding: 0.5rem 1.25rem 1rem 1.25rem;
}

.eventCard .eventDetail {
  font-weight: bold;
}

a {
  color: white;
  text-decoration: none;
}

a:hover
{
  color: blue;
}