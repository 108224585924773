.home-page
{
    background-color: #FC6A01;
    position: fixed;
    width: 100vw;
    height: 100%;
}

.logo-img
{
    width: 50%;
}

.home-center {
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 2rem;
    color: white;
}

.vertical-center
{
    margin-top: 25vh;
}
