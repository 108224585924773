.about-center
{
    text-align: center;
}

.about-content
{
    margin-left: 30%;
    margin-right: 20%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.about-content img
{
    width: 100%;
}

.about-a
{
    color: blue;
    text-decoration: underline;
}