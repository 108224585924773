.content
{
    padding-left: 20px;
}

.event-feed
{
    float: left;
    border: black;
    border-width: 5px 5px 5px 5px;
    width: 60%;
    padding-left: 20px;
    padding-bottom: 50px;
}

.right-container
{
    height: 100%;
    position: fixed;
    top: 0px;
    border: black;
    border-width: 5px 5px 5px 5px;
    float: right;
    padding-right: 50px;
    width: 30%;
    background-color: #FC6A01;
    right: -3%;
    padding: 20px;
    box-shadow: -4px 4px 4px 0px #00000040;
}

.right-container.hovered .events-window:not(.expanded) {
    height: 0%;
}

/* Common props of joined-events and hosted-events */
.events-window {
    height: 30%;
    width: 80%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    transition: 0.2s;
}

.events-window.expanded {
    height: 60%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.events-window::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.events-window {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.joined-events
{
    top: 0px;
}

.hosted-events
{
    top: 30%;
}
