.profile
{
    margin-top: 10%;
    margin-bottom: 15px;
    margin-left: 25%;
    margin-right: 20%;
    width: 40%;
    border: 3px solid #fc6a01;
    border-radius: 20px;
    text-align: 'center';
    align-items: center;
    padding: 20px;
    padding-bottom: 30px;
}

.profile-center
{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button {
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fc6a01;
    color: white;
    text-align: center;
    margin: 0 1rem;
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 5%;
    width: 30%;
  }

.button:hover {
    background-color: white;
    color: black;
    border-color: #fc6a01;
}