.header {
    width: 150px;
    height: 100%;
    list-style-type: none;
    display: flex;
    top: 0;
    background-color: #FC6A01;
    box-shadow: 4px 4px 4px 0px #00000040;
    position: fixed;
}

.header ul
{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    display: inline;
    align-items: center;
    vertical-align: top;
}

.header li
{
    margin-left: 15px;
    margin-right: 15px;
    line-height: 60px;
}

.header li a{
    font-size: 30px;
    font-weight: 500;
    color: white;
    text-decoration: none;
    text-align: center;
}

.header ul li a:hover,
.header li a:hover,
.header a:active {
    color: black;
}

.logo{
    width: 150px;
}

.list-end
{
    float:bottom;
}
