@font-face {
  font-family: "Istok Web";
  src: url('./files/IstokWeb-Regular.ttf'); 
}

body {
  font-family: 'Istok Web';
  margin: 0;
  padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content {
  margin-left: 150px;
  height: 100%;
  width: auto;
}

input 
{
  margin-top: 5px;
  margin-bottom: 5px;
}

p
{
  padding: 0px;
}