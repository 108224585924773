.this-backdrop
{
    display: block;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 50px;
    width: 67%;
    float: left;
}

.this-form
{
    float: right;
    padding-right: 50px;
    margin-top: 7%;
}

.center
{
    text-align: center;
}

.this-content
{
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 150px;
    height: 100%;
    width: auto;
}

.login-a
{
    color: blue;
    text-decoration: underline;
}