.button {
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fc6a01;
    color: white;
    text-align: center;
    margin: 0 1rem;
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 10%;
    width: 30%;
  }

.button:hover {
    background-color: white;
    color: black;
    border-color: #fc6a01;
}

.event-content
{
    margin-top: 10%;
    margin-bottom: 15%;
    margin-left: 40%;
    margin-right: 30%;
    width: 30%;
    border:#fc6a01 solid;
    border-width: 3px;
    border-radius: 10px;
    padding: 50px;
}