.backdrop
{
    display: block;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 50px;
    width: 67%;
    float: left;
}

.form
{
    float: right;
    padding-right: 50px;
    margin-top: 3%;
}

.center
{
    text-align: center;
}

.content
{
    margin-top: 0px;
    margin-bottom: 0px;
}

.register-a
{
    color: blue;
    text-decoration: underline;
}