.extras-content
{
    margin-top: 5%;
    margin-left: 30%;
    margin-right: 20%;
}

.extras-a
{
    color: blue;
    text-decoration: underline;
}